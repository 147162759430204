var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "rounded-lg border border-lightGrey-1 mt-8 p-2 md:p-4" },
        [
          _c(
            "div",
            { staticClass: "flex justify-between items-center" },
            [
              _c("h3", { staticClass: "text-black font-semibold" }, [
                _vm._v("Transfer to your wallet"),
              ]),
              _c("SecondaryButton", {
                attrs: { text: "Copy", p: "py-1.5 px-4" },
                on: {
                  click: function ($event) {
                    return _vm.copy(_vm.account.accountNumber)
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
            _c("p", { staticClass: "text-black" }, [_vm._v("Bank name:")]),
            _c("p", { staticClass: "text-black" }, [
              _vm._v(_vm._s(_vm.account.bankName)),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
            _c("p", { staticClass: "text-black" }, [_vm._v("Account name:")]),
            _c("p", { staticClass: "text-black" }, [
              _vm._v(_vm._s(_vm.account.accountName)),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
            _c("p", { staticClass: "text-black" }, [_vm._v("Account number:")]),
            _c("p", { staticClass: "text-black" }, [
              _vm._v(_vm._s(_vm.account.accountNumber)),
            ]),
          ]),
        ]
      ),
      _c("InlineToast", {
        staticClass: "mt-8",
        attrs: {
          display: "",
          description:
            "Withdrawing from your wallet without a purchase will attract a 2% fee",
          type: "info",
        },
      }),
      _c("Button", {
        staticClass: "mt-3",
        attrs: {
          loading: _vm.loading,
          text: "I have made the transfer",
          width: "w-full",
        },
        on: { click: _vm.updateWalletBalance },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }